import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="gantt"
export default class extends Controller {

    static values = {chartData: Array, height: Number, ident: String}

    connect() {
        let that = this

//        console.log(this.chartDataValue)
//        console.log('>>>>' + this.heightValue)

        google.charts.load('current', {'packages': ['gantt']});
        google.charts.setOnLoadCallback(function() { that.drawChart(that.chartDataValue, that.heightValue) });
    }


    drawChart(chart_data, height) {
        // console.log('>>>>' + height)

        let data = new google.visualization.DataTable();
        let first_time = true;

        data.addColumn('string', 'Task ID');
        data.addColumn('string', 'Task Name');
        data.addColumn('string', 'Resource');
        data.addColumn('date', 'Start Date');
        data.addColumn('date', 'End Date');
        data.addColumn('number', 'Duration');
        data.addColumn('number', 'Percent Complete');
        data.addColumn('string', 'Dependencies');

        for (const row of chart_data) {

            // console.log(row)

            // data.addRow([row[0], row[1], row[2], new Date(row[3], row[4], row[5]), new Date(row[6], row[7], row[8]), row[9], row[10], row[11]]);

            if (first_time) {
                data.addRow([row[0], row[1], row[2], new Date(2024, 1, 1), null, row[9], row[10], row[11]]);
                first_time = false
            } else {
                data.addRow([row[0], row[1], row[2], null, null, row[9], row[10], row[11]]);
            }


        }

        let options = {
            height: height,
            gantt: {
                defaultStartDate: new Date,
            },
        };

        let chart = new google.visualization.Gantt(document.getElementById(this.identValue));

        chart.draw(data, options);
    }
}
