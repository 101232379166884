// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "diagram", "steps", "self", "child" ]
  static values = { purposeDiagUrl: String, purposeStepsUrl: String}

  connect() {
    console.log('Activity#connect')
  }

  makeDiagram(event) {
    let that = this

    Rails.ajax({
      type: 'GET',
      url: this.purposeDiagUrlValue,
      dataType: 'json',
      success: function (response) {
        that.selfTarget.outerHTML = response.html
      },
      error: function (response) {
        console.log('Setting could not be saved.')
      }
    })
  }

  makeSteps(event) {
    let that = this

    Rails.ajax({
      type: 'GET',
      url: this.purposeStepsUrlValue,
      dataType: 'json',
      success: function (response) {
        that.selfTarget.outerHTML = response.html
      },
      error: function (response) {
        console.log('Setting could not be saved.')
      }
    })
  }

  getChild(event) {
    console.log(`Activity#getChild ${event.target.name}`)

    let that = this

    Rails.ajax({
      type: 'GET',
      url: event.target.name,
      dataType: 'json',
      success: function (response) {
        that.childTarget.innerHTML = response.html
        that.childTarget.scrollIntoView({behavior: "smooth"})
      },
      error: function (response) {
        console.log('Setting could not be saved.')
      }
    })
  }
}
