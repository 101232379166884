import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["query", "result"]

    connect() {
        console.log('Search: Connecting')
        this.url = this.data.get('url')
        console.log('Search: Connected ' + this.url )
    }

    queryChanged() {
        let that = this

        console.log('key pressed')
        // console.log('Path: ' + this.url + '?field=' + this.data.get("field") +'&by=' + this.queryTarget.value)

        Rails.ajax({
            type: 'GET',
            url: this.url + '?query=' + this.queryTarget.value,
            dataType: 'json',
            success: function (response) {
                console.log('We got a response:' + response.html)

                that.resultTarget.innerHTML = response.html
            },
            error: function (response) {
                console.log('filter failed')
            }
        })

    }
}