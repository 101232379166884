import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle"

window.bootstrap = bootstrap

Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

import "./controllers"
import $ from 'jquery'

console.log('Include: application-esbuild.js')