import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {Turbo} from "@hotwired/turbo-rails";
import consumer from "../channels/consumer";

class Bubble {
    constructor(myCanvas, x, y, id, name, url, controller) {
        this.controller = controller

        this.BUBBLE__WIDTH = 100
        this.BUBBLE__HEIGHT = 155
        this.BUBBLE__TEXT_WIDTH = this.BUBBLE__WIDTH - 10

        this.myCanvas = myCanvas
        this.dragging = false // Is the object being dragged?
        this.rollover = false // Is the mouse over the ellipse?
        this.removed = false
        this.connecting = false

        this.x = x;
        this.y = y;
        this.id = id

        // Dimensions
        this.w = this.BUBBLE__WIDTH;
        this.h = this.BUBBLE__HEIGHT
        this.name = name
        this.x_hook = -1000
        this.y_hook = -1000
        this.x_remove = -1000
        this.y_remove = -1000
        this.curve_radius = 20

        this.secL = this.myCanvas.random(0.2, 0.4);
        this.rand1 = this.myCanvas.random(-1.0, 1.0)
        this.rand2 = this.myCanvas.random(-1.0, 1.0)
        this.rand3 = this.myCanvas.random(-1.0, 1.0)
        this.rand4 = this.myCanvas.random(-1.0, 1.0)

        this.img = this.myCanvas.loadImage(url);

    }

    removeOperand() {
        console.log('StepDiagram#removeOperand' + this.id)

        Rails.ajax({
            type: 'GET',
            url: "/step_operands/" + this.id + '/remove',
            dataType: 'json',
            success: function (response) {
                console.log('removed')
            }
        })
        this.remove()
    }

    move(x, y) {
        this.x = x
        this.y = y
    }

    isConnected() {
        return this.connecting
    }

    finishConnecting() {
        this.connecting = false
    }

    is_rollover() {
        return this.rollover
    }

    is_removed() {
        return this.removed
    }

    current_x() {
        return this.x
    }

    current_y() {
        return this.y
    }


    over() {
        if (this.removed) {
            return
        }

        // Is mouse over object
        if (this.within_hook(this.x_hook, this.y_hook)) {
            this.rollover = true;
        } else {
            this.rollover = false;
        }

    }

    update() {

        // Adjust location if being dragged
        if (this.dragging) {
            this.x = this.myCanvas.mouseX + this.offsetX;
            this.y = this.myCanvas.mouseY + this.offsetY;
        }

    }

    show(controller) {
        let wanting_to_save = controller.isSaveImage()
        let connection_start = controller.bubble_start

        if (this.removed) {
            return
        }

        this.myCanvas.rectMode(this.myCanvas.CENTER);
        this.myCanvas.push()
        this.myCanvas.strokeWeight(2);
        // Different fill based on state

        if (this.dragging) {
            this.myCanvas.strokeWeight(4);
            this.myCanvas.fill(255, 200, 200);
        } else if (this.rollover) {
            this.myCanvas.stroke(255, 20, 20)
            this.myCanvas.fill(255, 200, 200);
            this.myCanvas.strokeWeight(4)
        } else {
            this.myCanvas.strokeWeight(2);
            this.myCanvas.fill('white');
        }

        if (this.external) {
            this.myCanvas.stroke(100)
            this.myCanvas.drawingContext.setLineDash([10, 5, 2, 5]);
        }
        // this.myCanvas.rect(this.x, this.y, this.w, this.h, 20);

        this.myCanvas.beginShape()

        this.vertex_wobble(
            this.x - this.w / 2 + this.curve_radius, this.y - this.h / 2,
            this.x + this.w / 2 - this.curve_radius, this.y - this.h / 2)
        this.myCanvas.bezierVertex(this.x + this.w / 2, this.y - this.h / 2, this.x + this.w / 2, this.y - this.h / 2, this.x + this.w / 2, this.y + this.curve_radius)
        this.vertex_wobble(this.x + this.w / 2, this.y + this.curve_radius, this.x + this.w / 2, this.y + this.h / 2 - this.curve_radius)
        this.myCanvas.bezierVertex(
            this.x + this.w / 2, this.y + this.h / 2,
            this.x + this.w / 2, this.y + this.h / 2,
            this.x + this.w / 2 - this.curve_radius, this.y + this.h / 2)

        // Bottom Edge
        this.vertex_wobble(
            this.x + this.w / 2 - this.curve_radius, this.y + this.h / 2,
            this.x + this.curve_radius, this.y + this.h / 2)
        this.myCanvas.bezierVertex(
            this.x - this.w / 2, this.y + this.h / 2,
            this.x - this.w / 2, this.y + this.h / 2,
            this.x - this.w / 2, this.y + this.h / 2 - this.curve_radius)
        this.vertex_wobble(
            this.x - this.w / 2, this.y + this.h / 2 - this.curve_radius,
            this.x - this.w / 2, this.y - this.h / 2 + this.curve_radius)

        this.myCanvas.bezierVertex(
            this.x - this.w / 2, this.y - this.h / 2,
            this.x - this.w / 2, this.y - this.h / 2,
            this.x - this.w / 2 + this.curve_radius, this.y - this.h / 2)

        this.myCanvas.endShape()
        this.myCanvas.pop()


        this.myCanvas.imageMode(this.myCanvas.CENTER)
        this.myCanvas.image(
            this.img,
            this.x, this.y - 30, 90, 90,
            0, 0, this.img.width, this.img.height,
            this.myCanvas.CONTAIN
        );

        // this.draw_bubble_background()


        this.myCanvas.push()
        // this.myCanvas.stroke(0);
        this.myCanvas.fill(0);
        this.myCanvas.textAlign(this.myCanvas.CENTER, this.myCanvas.CENTER);
        this.myCanvas.textWrap(this.myCanvas.WORD);
        this.myCanvas.text(this.name, this.x, this.y + 50, this.BUBBLE__TEXT_WIDTH)

        if (this.controller.editingValue && !wanting_to_save) {
            this.myCanvas.fill('white');
            this.x_hook = this.x - 40
            this.y_hook = this.y + 90
            this.arrows(this.x_hook, this.y_hook)
            //this is the remove button
            this.myCanvas.fill('white');
            this.x_remove = this.x
            this.y_remove = this.y + 90
            this.myCanvas.line(this.x_remove - 5, this.y_remove - 5, this.x_remove + 5, this.y_remove + 5)
            this.myCanvas.line(this.x_remove + 5, this.y_remove - 5, this.x_remove - 5, this.y_remove + 5)

        }
        this.myCanvas.pop()
        this.connector_hook(wanting_to_save, connection_start);

        return this.rollover
    }

    connector_hook(wanting_to_save, connection_start) {
        this.myCanvas.push()
        if (this.connecting) {
            this.myCanvas.stroke('green')
        }

        if (this.controller.editingValue && !wanting_to_save) {
            this.myCanvas.translate(this.x - 20, this.y + 90)
            if (connection_start && (connection_start !== this.id)) {
                this.myCanvas.line(5, -5, 5, 5)
                this.myCanvas.line(-5, 0, 5, 0)
                this.myCanvas.line(5, 0, -2, -5)
                this.myCanvas.line(5, 0, -2, 5)
            } else {
                this.myCanvas.line(-5, -5, -5, 5)
                this.myCanvas.line(-5, 0, 5, 0)
                this.myCanvas.line(5, 0, -2, -5)
                this.myCanvas.line(5, 0, -2, 5)
            }
        }
        this.myCanvas.pop()
    }

    arrows(x, y) {
        this.myCanvas.push()
        this.myCanvas.noFill()
        this.myCanvas.strokeWeight(1);
        this.myCanvas.stroke(0)

        this.myCanvas.line(x - 3, y + 4, x, y + 6)
        this.myCanvas.line(x, y + 6, x + 3, y + 4)
        this.myCanvas.line(x - 3, y - 4, x, y - 6)
        this.myCanvas.line(x, y - 6, x + 3, y - 4)
        this.myCanvas.line(x + 4, y - 3, x + 6, y)
        this.myCanvas.line(x + 6, y, x + 4, y + 3)
        this.myCanvas.line(x - 4, y + 3, x - 6, y)
        this.myCanvas.line(x - 6, y, x - 4, y - 3)
        this.myCanvas.pop()
    }

    clicked() {
        if (this.removed) {
            return
        }

        if (this.controller.editingValue) {
            if (this.within_hook(this.x - 20, this.y + 90)) {
                this.connecting = true
            }
        }
    }

    pressed() {
        if (this.removed) {
            return
        }

        // Did I click on the rectangle?
        if (this.within_bubble() && !this.controller.changingValue) {
            //todo add back in when needed

            // this.url = `/step_operands/${this.id}`
            // fetch(this.url, {
            //     headers: {
            //         Accept: "text/vnd.turbo-stream.html"
            //     }
            // })
            //     .then(r => r.text())
            //     .then(html => Turbo.renderStreamMessage(html))
        } else if (this.within_hook(this.x_remove, this.y_remove)) {
            this.removeOperand()
        }

        if (this.controller.editingValue) {
            if (this.within_hook(this.x_hook, this.y_hook)) {
                this.dragging = true;
                // If so, keep track of relative location of click to corner of rectangle
                this.offsetX = this.x - this.myCanvas.mouseX;
                this.offsetY = this.y - this.myCanvas.mouseY;
            }
        }

        return this.dragging
    }

    released() {
        if (this.dragging) {
            // Quit dragging
            this.dragging = false;

            Rails.ajax({
                type: 'PUT',
                url: `/step_operands/${this.id}`,
                dataType: 'html',
                data: "x=" + `${this.x}` + "&y=" + `${this.y}`,
                success: function (response) {
                },
                error: function (response) {
                }
            })
        }
    }

    remove() {
        this.removed = true
    }

    within_bubble() {
        if (this.myCanvas.mouseX < (this.x - this.BUBBLE__WIDTH / 2)) {
            return false
        }
        if (this.myCanvas.mouseX > (this.x + this.BUBBLE__WIDTH / 2)) {
            return false
        }
        if (this.myCanvas.mouseY < (this.y - this.BUBBLE__HEIGHT / 2)) {
            return false
        }
        return this.myCanvas.mouseY <= (this.y + this.BUBBLE__HEIGHT / 2);

    }

    within_hook(x, y) {
        if (this.myCanvas.mouseX < (x - 10)) {
            return false
        }
        if (this.myCanvas.mouseX > (x + 10)) {
            return false
        }
        if (this.myCanvas.mouseY < (y - 10)) {
            return false
        }
        if (this.myCanvas.mouseY > (y + 10)) {
            return false
        }
        return true
    }

    vertex_wobble(x_from, y_from, x_to, y_to) {
        let dLen = this.myCanvas.dist(x_from, y_from, x_to, y_to) * 0.03;
        let trdL = this.secL * 2.0;

        let x_mid = (x_from + x_to) / 2
        let y_mid = (y_from + y_to) / 2

        this.myCanvas.vertex(x_from, y_from);
        this.myCanvas.bezierVertex(
            this.myCanvas.lerp(x_from, x_mid, this.secL) + this.rand1 * dLen,
            this.myCanvas.lerp(y_from, y_mid, this.secL) + this.rand2 * dLen,
            this.myCanvas.lerp(x_from, x_mid, trdL) + this.rand3 * dLen,
            this.myCanvas.lerp(y_from, y_mid, trdL) + this.rand4 * dLen,
            x_mid,
            y_mid
        );
        this.myCanvas.bezierVertex(
            this.myCanvas.lerp(x_mid, x_to, trdL) - this.rand4 * dLen,
            this.myCanvas.lerp(y_mid, y_to, trdL) - this.rand3 * dLen,
            this.myCanvas.lerp(x_mid, x_to, this.secL) - this.rand2 * dLen,
            this.myCanvas.lerp(y_mid, y_to, this.secL) - this.rand1 * dLen,
            x_to,
            y_to
        );
    }

}

class Connector {
    constructor(myCanvas, id, b1, b2, style, controller) {

        console.log(`>>>> Connector#constructor: ${b1} -> ${b2} id: ${id}`)

        this.controller = controller

        this.CURVE_RADIUS = 40

        this.myCanvas = myCanvas

        // The ids of the bubbles
        this.b1 = b1
        this.b2 = b2

        this.style = style
        this.bubbles = controller.bubbles_new
        this.id = id
        // this.height = height
        // this.below_line = direction
        // this.hidden = hidden

        this.rollover = false
        this.grabbed = false
        this.x_hook = -1000
        this.y_hook = -1000
        this.removed = false

        this.secL = this.myCanvas.random(0.2, 0.4);
        this.rand1 = this.myCanvas.random(-1.0, 1.0)
        this.rand2 = this.myCanvas.random(-1.0, 1.0)
        this.rand3 = this.myCanvas.random(-1.0, 1.0)
        this.rand4 = this.myCanvas.random(-1.0, 1.0)
    }

    over() {
        // Is mouse over object
        this.rollover = this.within_arrow();
    }

    update() {
        // Adjust location if being dragged
        if (this.dragging) {
            this.x = this.myCanvas.mouseX + this.offsetX;
            this.y = this.myCanvas.mouseY + this.offsetY;
        }
    }

    styling(style, direction, height, hidden) {
        this.style = style
        this.below_line = direction
        this.height = height
        this.hidden = hidden
    }

    remove() {
        this.removed = true
    }

    show(wanting_to_save = false) {
        if (this.removed) {
            return
        }

        let b_from = this.bubbles[this.b1]
        let b_to = this.bubbles[this.b2]

        if (b_from.is_removed() || b_to.is_removed()) {
            this.remove()
            return
        }
        this.myCanvas.push()

        if (this.hidden) {
            this.myCanvas.drawingContext.setLineDash([2, 2]);
        }

        if (this.rollover) {
            this.myCanvas.stroke(255, 20, 20)
            this.myCanvas.strokeWeight(3)
        }

        if (b_from && b_from.is_rollover()) {
            this.myCanvas.stroke(255, 20, 20)
            this.myCanvas.strokeWeight(3)
        }

        if (b_to && b_to.is_rollover()) {
            this.myCanvas.stroke(255, 20, 20)
            this.myCanvas.strokeWeight(3)
        }

        if (this.b2 == null) {
            return
        }

        let x_from = this.bubbles[this.b1].current_x()
        let y_from = this.bubbles[this.b1].current_y()
        let x_to = this.bubbles[this.b2].current_x()
        let y_to = this.bubbles[this.b2].current_y()

        if (this.b1 == this.b2) {
            this.myCanvas.ellipse(x_from + 60, y_from + 30, 70, 40)

            this.arrow(
                x_from + 70, y_from + 10,
                x_from + 50, y_from + 10
            )

        } else if (this.style == 'optional-after') {
            let x_offset_end = (x_from > x_to ? -10 : 10)
            let x_curve = (x_from > x_to ? this.CURVE_RADIUS - x_offset_end : -this.CURVE_RADIUS - x_offset_end)
            let y_curve = (y_from > y_to ? -this.CURVE_RADIUS : this.CURVE_RADIUS)
            let x_edge = (x_from < x_to ? x_from + 100 / 2 : x_from - 100 / 2)
            let y_edge = (y_from < y_to ? y_to - 55 / 2 : y_to + 55 / 2)

            this.myCanvas.push()
            this.myCanvas.noFill();
            this.myCanvas.beginShape()
            this.vertex_wobble(x_edge, y_from, x_to + x_curve, y_from)
            this.myCanvas.bezierVertex(
                x_to - x_offset_end, y_from,
                x_to - x_offset_end, y_from,
                x_to - x_offset_end, y_from + y_curve);
            this.vertex_wobble(x_to - x_offset_end, y_from + y_curve, x_to - x_offset_end, y_edge)
            this.myCanvas.endShape()
            this.myCanvas.pop()


            this.arrow_with_hook(
                x_edge, y_from,
                x_to + x_curve, y_from,
                wanting_to_save
            )

            this.arrow(
                x_to - x_offset_end, y_from + y_curve,
                x_to - x_offset_end, y_edge
            )
        } else if (this.style == 'optional') {

            let x_offset_start = (x_from > x_to ? -10 : 10)
            let x_curve = (x_from > x_to ? -50 : 50)
            let y_curve = (y_from > y_to ? 40 : -40)
            let x_edge_end = (x_from < x_to ? x_to + x_offset_start - 100 / 2 : x_to + x_offset_start + 100 / 2)
            let y_edge = (y_from > y_to ? y_from - 55 / 2 : y_from + 55 / 2)

            this.myCanvas.push()
            this.myCanvas.noFill();
            this.myCanvas.beginShape()
            this.vertex_wobble(x_from + x_offset_start, y_edge, x_from + x_offset_start, y_to + y_curve)
            this.myCanvas.bezierVertex(x_from + x_offset_start, y_to, x_from + x_offset_start, y_to, x_from + x_curve, y_to);
            this.vertex_wobble(x_from + x_curve, y_to, x_edge_end, y_to)
            this.myCanvas.endShape()
            this.myCanvas.pop()


            // Vertical Arrow
            this.arrow(
                x_from + x_offset_start, y_edge,
                x_from + x_offset_start, y_to + y_curve
            )

            this.arrow_with_hook(
                x_from + x_curve, y_to,
                x_edge_end, y_to,
                wanting_to_save
            )
        } else if (this.style == 'over') {
            let total_height = (this.below_line ? +50 + this.height * 50 : -50 - this.height * 50)
            let y_top = (y_from < y_to ? y_from + total_height : y_to + total_height)

            let x_offset_start = (x_from > x_to ? -10 : 10)
            let x_curve = (x_from > x_to ? -50 : 50)
            let y_curve = (this.below_line ? -40 : 40)

            this.myCanvas.push()
            this.myCanvas.noFill();
            this.myCanvas.beginShape()
            this.vertex_wobble(x_from, y_from, x_from, y_top + y_curve)
            this.myCanvas.bezierVertex(x_from, y_top, x_from, y_top, x_from + x_curve, y_top);
            this.vertex_wobble(x_from + x_curve, y_top, x_to - x_curve, y_top)
            this.myCanvas.bezierVertex(x_to + x_offset_start, y_top, x_to + x_offset_start, y_top, x_to + x_offset_start, y_top + y_curve);
            this.vertex_wobble(x_to + x_offset_start, y_top + y_curve, x_to + x_offset_start, y_to)
            this.myCanvas.endShape()
            this.myCanvas.pop()

            this.arrow_with_hook(x_from, y_top,
                x_to, y_top,
                wanting_to_save
            )
        } else if (this.style == 'curve') {

            let x_mid = (x_from + x_to) / 2.0
            let y_mid = (y_from + y_to) / 2.0

            this.myCanvas.push()
            this.myCanvas.noFill()
            this.myCanvas.beginShape();
            this.myCanvas.curveVertex(x_from, y_from)
            this.myCanvas.curveVertex(x_from, y_from)
            this.myCanvas.curveVertex(
                x_mid + (y_to - y_from) * 0.13,
                y_mid + (x_from - x_to) * 0.13
            )
            this.myCanvas.curveVertex(x_to, y_to)
            this.myCanvas.curveVertex(x_to, y_to)
            this.myCanvas.endShape();
            this.myCanvas.pop()

            this.arrow_with_hook(
                x_from + (y_to - y_from) * 0.13,
                y_from + (x_from - x_to) * 0.13,
                x_to + (y_to - y_from) * 0.13,
                y_to + (x_from - x_to) * 0.13,
                wanting_to_save
            )
        } else {


            if (this.bubbles[this.b1]) {
                this.myCanvas.push()
                this.myCanvas.beginShape()
                this.vertex_wobble(
                    x_from,
                    y_from,
                    x_to,
                    y_to
                )
                this.myCanvas.endShape()

                this.arrow_with_hook(
                    x_from,
                    y_from,
                    x_to,
                    y_to,
                    wanting_to_save
                )
                this.myCanvas.pop()
            }
        }
        this.myCanvas.pop()

        return this.rollover
    }


    vertex_wobble(x_from, y_from, x_to, y_to) {
        let dLen = this.myCanvas.dist(x_from, y_from, x_to, y_to) * 0.03;
        let trdL = this.secL * 2.0;

        let x_mid = (x_from + x_to) / 2
        let y_mid = (y_from + y_to) / 2

        this.myCanvas.vertex(x_from, y_from);
        this.myCanvas.bezierVertex(
            this.myCanvas.lerp(x_from, x_mid, this.secL) + this.rand1 * dLen,
            this.myCanvas.lerp(y_from, y_mid, this.secL) + this.rand2 * dLen,
            this.myCanvas.lerp(x_from, x_mid, trdL) + this.rand3 * dLen,
            this.myCanvas.lerp(y_from, y_mid, trdL) + this.rand4 * dLen,
            x_mid,
            y_mid
        );
        this.myCanvas.bezierVertex(
            this.myCanvas.lerp(x_mid, x_to, trdL) - this.rand4 * dLen,
            this.myCanvas.lerp(y_mid, y_to, trdL) - this.rand3 * dLen,
            this.myCanvas.lerp(x_mid, x_to, this.secL) - this.rand2 * dLen,
            this.myCanvas.lerp(y_mid, y_to, this.secL) - this.rand1 * dLen,
            x_to,
            y_to
        );
    }

    pressed(i) {
        if (this.controller.editingValue) {
            if (this.grabbed === true) {
                return
            } // Don't keep pressing

            // Did I click on the rectangle?
            if (this.within_arrow()) {
                this.grabbed = true
                this.controller.changingValue = true

                this.url = `/operand_followings/${this.id}/edit?index=${i}`
                fetch(this.url, {
                    headers: {
                        Accept: "text/vnd.turbo-stream.html"
                    }
                })
                    .then(r => r.text())
                    .then(html => Turbo.renderStreamMessage(html))
            }
        }
    }

    released() {
        this.grabbed = false
    }

    within_arrow() {

        if (this.myCanvas.mouseX < (this.x_hook - 10)) {
            return false
        }
        if (this.myCanvas.mouseX > (this.x_hook + 10)) {
            return false
        }
        if (this.myCanvas.mouseY < (this.y_hook - 10)) {
            return false
        }
        if (this.myCanvas.mouseY > (this.y_hook + 10)) {
            return false
        }
        return true
    }

    arrow_with_hook(x_from, y_from, x_to, y_to, wanting_to_save) {
        this.arrow(x_from, y_from, x_to, y_to)

        if (this.controller.editingValue && !this.controller.changingValue) {
            this.x_hook = (x_to + x_from) / 2
            this.y_hook = (y_to + y_from) / 2 + 20

            if (this.rollover) {
                this.myCanvas.fill(255, 200, 200)
            } else {
                this.myCanvas.noFill()
            }
            this.myCanvas.circle(
                this.x_hook,
                this.y_hook,
                10
            )
        }
    }

    arrow(x_from, y_from, x_to, y_to) {
        this.myCanvas.push()
        this.myCanvas.fill('white')

        let norm = this.myCanvas.createVector(
            x_to - x_from,
            y_to - y_from)

        norm.normalize()
        this.myCanvas.translate(
            (x_to + x_from) / 2,
            (y_to + y_from) / 2)

        // applyMatrix(1,0,0,1,vector.x - start.x,vector.y - start.y)
        this.myCanvas.applyMatrix(norm.x, norm.y, -norm.y, norm.x, 0, 0)
        this.myCanvas.triangle(-5, 3, 7, 0, -5, -3)
        this.myCanvas.pop()
    }
}


// Connects to data-controller="step-diagram"
export default class extends Controller {

    static values = {
        operands: Array,
        id: String,
        ident: String,
        newLib: String,
        editing: Boolean,
        needsSaving: Boolean
    }

    connect() {
        console.log(`ident: #${this.identValue}`)

        this.bubbles_new = []
        this.connectors_new = []
        this.bubble_start = null
        this.bubble_end = null
        this.saving_delay = 10

        if (this.newLibValue) {
            this.sketch = this.sketch_idnameofdiv(this)
            this.myCanvas = new p5(this.sketch, "myContainer_" + this.idValue)
            // this.myCanvas.freddie = "hello"
        }

        this.draw()
        this.subscription()

        // Wait until everything has been drawn!
        this.save_image = true
    }

    isSaveImage() {
        return this.save_image && this.needsSavingValue === true
    }

    subscription() {
        console.log('StepDiagram#subscription id:' + this.idValue)

        if (this._subscription === undefined) {
            let that = this
            this._subscription = consumer.subscriptions.create({
                channel: "StepDiagramChannel",
                id: that.idValue
            }, {
                connected() {
                    console.log('StepDiagram#connected')
                }, disconnected() {
                    console.log('StepDiagram#disconnected')
                }, received(data) {
                    console.log('StepDiagram#received')
                    that.changingValue = false;

                    if (data.action === 'style-connector') {
                        that.connectors_new[data.index].styling(
                            data.bubble_connector.style,
                            data.bubble_connector.line_below,
                            data.bubble_connector.height,
                            data.bubble_connector.hidden)
                    }

                    if (data.action === 'new-connector') {
                        that.connectors_new[data.connector.id] = new Connector(
                            that.myCanvas,
                            data.from_id,
                            data.to_id,
                            data.style,
                            that.bubbles_new,
                            data.connector.id,
                            0,
                            null,
                            false,
                            that)
                    }

                    if (data.action === 'remove-connector') {
                        that.connectors_new[data.bubble_connector.id].remove()
                    }

                    if (data.action === 'refresh-connectors') {
                        let j = null

                        for (j in that.edit_buttons) {
                            that.edit_buttons[j].remove()
                        }
                        that.draw(false)
                    }

                    if (data.action === 'new') {
                        that.draw_bubble(
                            data.bubble.id,
                            data.bubble.x,
                            data.bubble.y,
                            that.drag,
                            data.activity.name,
                            data.activity.id,
                            data.activity.purpose,
                            data.activity.parent_id,
                            data.parent_name);
                        that.draw_bubble_text(data.bubble.id, data.activity.id, data.bubble.x, data.bubble.y, data.activity.purpose, data.activity.name, data.activity.parent_id, data.parent_name);
                    }

                    if (data.action === 'refresh-bubble') {
                        that.bubbles_new[data.bubble.id].move(data.bubble.x, data.bubble.y)
                    }

                    if (data.action === 'remove') {
                        that.bubbles_new[data.bubble.id].remove()
                    }

                    //not being called anywhere
                    if (data.action === 'connector-replace') {
                        that.connectors[data.id]
                            .attr("stroke", "#a88")
                            .attr("d", data.connection.path)
                            .attr("fill", "none").attr("stroke-width", "2px")

                        that.connector_arrows[data.id]
                            .attr("stroke", "#a88")
                            .attr("d", data.connection.arrow)
                            .attr("fill", "none").attr("stroke-width", "2px")

                        if (data.connection.hidden) {
                            that.connectors[data.id].style("stroke-dasharray", ("3, 3"))
                                .attr("marker-mid", "url(#Arrow)")

                            that.connector_arrows[data.id].style("stroke-dasharray", ("3, 3"))
                                .attr("marker-mid", "url(#Arrow)")
                        } else {
                            that.connectors[data.id].style("stroke-dasharray", null)
                                .attr("marker-mid", "url(#Arrow)")

                            that.connector_arrows[data.id].style("stroke-dasharray", null)
                                .attr("marker-mid", "url(#Arrow)")
                        }

                        if (that.editingValue) {
                            console.log(`>> ${data.id}`)

                            that.edit_buttons[data.id].attr("x", data.connection.edit_x)
                                .attr("y", data.connection.edit_y);
                        }
                    }
                },
            })
        }
        return this._subscription
    }

    sketch_idnameofdiv(that) {
        let i

        let add_connector = function () {
            that.connectors_new.push(
                new Connector(that.myCanvas, null,
                    that.bubble_start,
                    that.bubble_end,
                    null,
                    that))
            that.bubbles_new[that.bubble_start].finishConnecting()
            that.bubbles_new[that.bubble_end].finishConnecting()

            that.bubble_start = null
            that.bubble_end = null
        }

        return function (p) {

            p.setup = function () {
                // let bounding = document.getElementById(`myContainer`).getBoundingClientRect()
                p.createCanvas(1000, 500);
                p.textSize(11);
                p.frameRate(10);
            }

            p.draw = function () {
                let rollOver = false
                p.background('white');
                // this.can_save = true

                that.myCanvas.noFill()

                for (i in that.connectors_new) {
                    console.log("CONNECTION " + that.bubble_start)
                    rollOver = that.connectors_new[i].show(that.isSaveImage()) || rollOver
                }

                for (i in that.bubbles_new) {
                    rollOver = that.bubbles_new[i].show(that) || rollOver
                }

                if (that.isSaveImage()) {
                    that.send_image()
                }
            }

            p.mouseMoved = function () {
                for (i in that.connectors_new) {
                    that.connectors_new[i].over()
                }

                for (i in that.bubbles_new) {
                    that.bubbles_new[i].over()
                }
            }

            p.mouseClicked = function () {

                let i

                for (i in that.bubbles_new) {
                    that.bubbles_new[i].clicked()

                    if (that.bubbles_new[i].isConnected()) {
                        if (that.bubble_start == null) {
                            that.bubble_start = that.bubbles_new[i].id
                        } else if (that.bubble_end == null && (that.bubble_start !== that.bubbles_new[i].id)) {
                            that.bubble_end = that.bubbles_new[i].id
                        }
                    }

                }

                if (that.bubble_start && that.bubble_end) {
                    // Write this back to the server
                    this.url = `/step_operands/${that.bubbles_new[that.bubble_start].id}/following_to?follower_id=${that.bubbles_new[that.bubble_end].id}`

                    fetch(this.url, {
                        headers: {
                            Accept: "text/vnd.turbo-stream.html"
                        }
                    })
                        .then(r => r.text())
                        .then(html => Turbo.renderStreamMessage(html))
                        .then(r => add_connector())
                }

            }

            p.mousePressed = function () {
                let something_pressed = false

                for (i in that.bubbles_new) {
                    if (!something_pressed) {
                        something_pressed = that.bubbles_new[i].pressed()
                    }
                }

                for (i in that.connectors_new) {
                    that.connectors_new[i].pressed(i)
                }
            }

            p.mouseDragged = function () {
                for (i in that.bubbles_new) {
                    that.bubbles_new[i].update()
                }

            }

            p.mouseReleased = function () {
                for (i in that.connectors_new) {
                    that.connectors_new[i].released()
                }

                for (i in that.bubbles_new) {
                    that.bubbles_new[i].released()
                }
            }
        }
    }

    send_image() {
        if (this.saving_delay > 0) {
            console.log("TRYING TO UPDATE STEP")
            this.saving_delay = this.saving_delay - 1
            return
        }

        if (this.isSaveImage()) {
            let canvas_img = this.myCanvas.canvas.toDataURL()

            let url = `/activity_steps/${this.idValue}`
            let data = new FormData()
            data.append('activity_step[diagram_img]', canvas_img)

            Rails.ajax({
                type: 'PATCH',
                url: url,
                dataType: 'json',
                data: data,
                success: function (response) {
                    console.log('IMG save succeeded')
                },
                error: function (response) {
                    console.log('IMG save failed')
                }
            })
            console.log("FINISHED UPDATING STEP")
            this.save_image = false
        }
    }

    draw() {
        let i, j
        console.log("initial draw")
        for (i in this.operandsValue) {
            let followers = JSON.parse(this.operandsValue[i].operand_followings)

            for (j in followers) {

                this.connectors_new.push(new Connector(
                    this.myCanvas,
                    followers[j].id, this.operandsValue[i].id,
                    followers[j].follower_id,
                    followers[j].style,
                    this))
            }

            this.bubbles_new[this.operandsValue[i].id] = new Bubble(
                this.myCanvas,
                this.operandsValue[i].x, this.operandsValue[i].y,
                this.operandsValue[i].id,
                this.operandsValue[i].name, this.operandsValue[i].url, this)

        }
    }
}


